import axios from "axios";

//"http://localhost:5000/api"

export default axios.create({
  baseURL: "https://api.ndiolfouta.com/api",
  //baseURL : process.env.REACT_APP_API_URL || "http://localhost:7000/api",
  //baseURL: "http://localhost:7000/api",

  //Docker
   //baseURL: "http://2.56.212.143:7000/api",
    //Prod
  //baseURL: "https://api.ndiolfouta.com/api",
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*"

  },
  
});

